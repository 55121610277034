@import "bootstrap/_mixins.scss";
@import "bootstrap/_variables.scss";
@import "../../../node_modules/font-awesome/scss/font-awesome";
@import "mixins";
@import "variables";
@import "variables.md";

@import "bs";
@import "ui";
@import "utils";
@import "md";

@import "add";

body {
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 600;
}

.has-error {
  .help-block {
    color: red;
  }
}