.label{
  display: inline-block;
  padding: .25em .5em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: bold;
  background-color: $label-bg;
  color: #fff;
  border-radius: .2rem;
  &.no-bg{
    background-color: transparent;
    color: inherit;
  }
}

.label{
  &.up{
    position: relative;
    top: -10px;
    min-width: 4px;
    min-height: 4px;
    display: inline-block;
  }
}

.label-lg{
  font-size: 1.33rem;
  padding: 5px 9px;
}

.label-sm{
  padding: 1px 4px;
}

.label-xs{
  padding: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.list-group-item.active > .label, 
.nav-pills > .active > a > .label{
  color: $dark-black;
}

.btn .label {
  position: relative;
  top: -1px;
}
