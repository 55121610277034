.add {
  background-color: #F2F2F2;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
  color: #eaeaea;
}

.add-800-100 {

}

.add-100 {
  height: 100px;
  font-size: 45px;
  padding-top: 18px;
}

.add-150 {
  height: 150px;
}

.add-200 {
  height: 200px;
}

.add-250 {
  height: 250px;
}

.add-300 {
  height: 300px;
  font-size: 24px;
  padding-top: 130px;
}

.add-350 {
  height: 350px;
}