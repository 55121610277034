.m-a { margin:        ($spacer * 1); }
.m-t { margin-top:    ($spacer-y * 1); }
.m-r { margin-right:  ($spacer-y * 1); }
.m-b { margin-bottom: ($spacer-y * 1); }
.m-l { margin-left:   ($spacer-y * 1); }
.m-x { margin-right:  ($spacer-x * 1); margin-left:   ($spacer-x * 1); }
.m-y { margin-top:    ($spacer-y * 1); margin-bottom: ($spacer-y * 1); }

.m-a-lg { margin:        ($spacer * 3); }
.m-t-lg { margin-top:    ($spacer-y * 3); }
.m-r-lg { margin-right:  ($spacer-y * 3); }
.m-b-lg { margin-bottom: ($spacer-y * 3); }
.m-l-lg { margin-left:   ($spacer-y * 3); }
.m-x-lg { margin-right:  ($spacer-x * 3); margin-left:   ($spacer-x * 3); }
.m-y-lg { margin-top:    ($spacer-y * 3); margin-bottom: ($spacer-y * 3); }

.m-a-md { margin:        ($spacer * 1.5); }
.m-t-md { margin-top:    ($spacer-y * 1.5); }
.m-r-md { margin-right:  ($spacer-y * 1.5); }
.m-b-md { margin-bottom: ($spacer-y * 1.5); }
.m-l-md { margin-left:   ($spacer-y * 1.5); }
.m-x-md { margin-right:  ($spacer-x * 1.5); margin-left:   ($spacer-x * 1.5); }
.m-y-md { margin-top:    ($spacer-y * 1.5); margin-bottom: ($spacer-y * 1.5); }

.m-a-sm { margin:        ($spacer * 0.5); }
.m-t-sm { margin-top:    ($spacer-y * 0.5); }
.m-r-sm { margin-right:  ($spacer-y * 0.5); }
.m-b-sm { margin-bottom: ($spacer-y * 0.5); }
.m-l-sm { margin-left:   ($spacer-y * 0.5); }
.m-x-sm { margin-right:  ($spacer-x * 0.5); margin-left:   ($spacer-x * 0.5); }
.m-y-sm { margin-top:    ($spacer-y * 0.5); margin-bottom: ($spacer-y * 0.5); }

.m-a-xs { margin:        ($spacer * 0.25); }
.m-t-xs { margin-top:    ($spacer-y * 0.25); }
.m-r-xs { margin-right:  ($spacer-y * 0.25); }
.m-b-xs { margin-bottom: ($spacer-y * 0.25); }
.m-l-xs { margin-left:   ($spacer-y * 0.25); }
.m-x-xs { margin-right:  ($spacer-x * 0.25); margin-left:   ($spacer-x * 0.25); }
.m-y-xs { margin-top:    ($spacer-y * 0.25); margin-bottom: ($spacer-y * 0.25); }

.p-a { padding:        ($spacer * 1); }
.p-t { padding-top:    ($spacer-y * 1); }
.p-r { padding-right:  ($spacer-y * 1); }
.p-b { padding-bottom: ($spacer-y * 1); }
.p-l { padding-left:   ($spacer-y * 1); }
.p-x { padding-right:  ($spacer-x * 1); padding-left:   ($spacer-x * 1); }
.p-y { padding-top:    ($spacer-y * 1); padding-bottom: ($spacer-y * 1); }

.p-a-lg { padding:        ($spacer * 3); }
.p-t-lg { padding-top:    ($spacer-y * 3); }
.p-r-lg { padding-right:  ($spacer-y * 3); }
.p-b-lg { padding-bottom: ($spacer-y * 3); }
.p-l-lg { padding-left:   ($spacer-y * 3); }
.p-x-lg { padding-right:  ($spacer-x * 3); padding-left:   ($spacer-x * 3); }
.p-y-lg { padding-top:    ($spacer-y * 3); padding-bottom: ($spacer-y * 3); }

.p-a-md { padding:        ($spacer * 1.5); }
.p-t-md { padding-top:    ($spacer-y * 1.5); }
.p-r-md { padding-right:  ($spacer-y * 1.5); }
.p-b-md { padding-bottom: ($spacer-y * 1.5); }
.p-l-md { padding-left:   ($spacer-y * 1.5); }
.p-x-md { padding-right:  ($spacer-x * 1.5); padding-left:   ($spacer-x * 1.5); }
.p-y-md { padding-top:    ($spacer-y * 1.5); padding-bottom: ($spacer-y * 1.5); }

.p-a-sm { padding:        ($spacer * 0.5); }
.p-t-sm { padding-top:    ($spacer-y * 0.5); }
.p-r-sm { padding-right:  ($spacer-y * 0.5); }
.p-b-sm { padding-bottom: ($spacer-y * 0.5); }
.p-l-sm { padding-left:   ($spacer-y * 0.5); }
.p-x-sm { padding-right:  ($spacer-x * 0.5); padding-left:   ($spacer-x * 0.5); }
.p-y-sm { padding-top:    ($spacer-y * 0.5); padding-bottom: ($spacer-y * 0.5); }

.p-a-xs { padding:        ($spacer * 0.25); }
.p-t-xs { padding-top:    ($spacer-y * 0.25); }
.p-r-xs { padding-right:  ($spacer-y * 0.25); }
.p-b-xs { padding-bottom: ($spacer-y * 0.25); }
.p-l-xs { padding-left:   ($spacer-y * 0.25); }
.p-x-xs { padding-right:  ($spacer-x * 0.25); padding-left:   ($spacer-x * 0.25); }
.p-y-xs { padding-top:    ($spacer-y * 0.25); padding-bottom: ($spacer-y * 0.25); }

.padding{
  padding: 1.5rem 1.5rem;
}
.margin{
  margin-bottom: 1.5rem;
}

.padding-out{
  margin: -1.5rem -1.5rem;
}

@include media-breakpoint-down(md) {
  .padding{
    padding: 1.5rem 1rem;
  }
  .padding-out{
    margin: -1.5rem -1rem;
  }
  .margin{
    margin-bottom: 1rem;
  }
  .box,
  .box-color{
    margin-bottom: 1rem;
  }
  .no-padding-md{
    padding: 0 !important;
  }
  .no-margin-md{
    margin: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .padding{
    padding: 1.5rem 0.5rem;
  }
  .padding-out{
    margin: -1.5rem -0.5rem;
  }
  .margin{
    margin-bottom: 0.5rem;
  }
  .box,
  .box-color{
    margin-bottom: 0.5rem;
  }
  .no-padding-sm{
    padding: 0 !important;
  }
  .no-margin-sm{
    margin: 0 !important;
  }
}

@include media-breakpoint-down(xs) {
  .no-padding-xs{
    padding: 0 !important;
  }
  .no-margin-xs{
    margin: 0 !important;
  }
}
